import $ from 'jquery';
import './scripts/common';
import onInitModulesInScope from './scripts/onInitModulesInScope';

//import 'popper.js';
// import 'bootstrap/js/dist/alert';
// import 'bootstrap/js/dist/button';
// import 'bootstrap/js/dist/carousel';
import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
// import 'bootstrap/js/dist/popover';
// import 'bootstrap/js/dist/scrollspy';
// import 'bootstrap/js/dist/tab';
// import 'bootstrap/js/dist/toast';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = window['app'] || {
    debug: false,
};
window.app = app || {};
window.app.modules =  app.modules || {};

import initModulesInScope from "./libs/@elements/init-modules-in-scope";
app.initModules = function ($scope) {
    console.warn('\'app.initModules\' is going to be deprecated and should not be used anymore. Please import and use \'initModulesInScope\' from \'./scripts/initModulesInScope\' instead.');
    initModulesInScope($scope);
};

$.loadScript = function (url) {
    console.warn('\'$.loadScript\' is going to be deprecated and should not be used anymore. Please use \'import\' for local files or import and use \'loadScript\' for external resources.');

    return import('./libs/@elements/load-script').then(function (loadScript) {
        return loadScript.default(url);
    });
};

/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as bookingWidget from './scripts/bookingWidget';
onInitModulesInScope(bookingWidget.initInScope);

import * as countup from './scripts/countup';
onInitModulesInScope(countup.initInScope);


(function( $ ){
    "use strict";

    /* -> _config._preload = Load this functions first */
    if (_config['_preload']) {
        $.each( _config['_preload'], function( _key, _val ){
            if( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ){
                window.app[_key]();
            }
        });
    }

    /* -> _config = Load all others (not _preload and _reload) */
    $.each( _config, function( _key, _val ){
        if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' && _key != '_reload' && _key != '_preload' ) ){
            window.app[_key]();
        }
    });

    /* -> _config._reload = Load the ajaxInclued and others after the rest */
    if (_config['_reload']) {
        $.each( _config['_reload'], function( _key, _val ){
            if( ( typeof _val == 'boolean' && typeof window.app[_key] == 'function' ) ){
                window.app[_key]();
            }
        });
    }

    initModulesInScope();
})($);
